.mobileNavBox {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  alignitems: center;
  visibility: hidden;
  background-color: white;
}
.mobileUL {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
}
.liStlye {
  font-size: 25px;
}

@media (max-width: 600px) {
  .mobileNavBox {
    visibility: visible;
    position: absolute;
    z-index: 10;
  }
}

@media all and (min-width: 280px) and (max-width: 370px) {
  .imgLogo {
    width: 261px;
    height: 243px;
  }
}
