.locationBox {
  height: 600px;
  width: auto;
  /* background-color: #75A149; */
  /* justify-content: center; */
  font-family: "Permanent Marker", cursive;
  display: flex;
  margin-top: 92px;
}

#col-1 {
  background-color: #75a149;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  margin: 0;
  gap: 25px;
}
.locationTxt {
  font-size: 25px;
  margin-left: 200px;
  margin-right: 200px;
}

#col-2 {
  background-color: white;
  flex: 1;
}

.socialMediaIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
}
.sombrero {
  width: 300px;
  height: 180px;
}
.facebook {
  width: 90px;
  height: 90px;
}
.instagram {
  width: 90px;
  height: 90px;
}
.grubhub {
  width: 90px;
  height: 90px;
}
.google {
  width: 90px;
  height: 90px;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  #col-1 {
    background-color: #75a149;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin: 0;
    gap: 40px;
  }
  .locationTxt {
    font-size: 25px;
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .locationBox {
    flex-direction: column;
  }
  .locationBox {
    height: 1000px;
    width: auto;
    font-family: "Permanent Marker", cursive;
    display: flex;
    margin-top: -27px;
  }
  #col-1 {
    gap: 4px;
  }
}
@media all and (max-width: 768px) {
  .locationBox {
    flex-direction: column;
  }
  .locationBox {
    height: 900px;
    width: auto;
    font-family: "Permanent Marker", cursive;
    display: flex;
    margin-top: -27px;
  }
  #col-1 {
    background-color: #75a149;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin: 0;
    gap: 1px;
  }
  .locationTxt {
    font-size: 25px;
    margin-left: 150px;
    margin-right: 150px;
  }
  .facebook {
    width: 80px;
    height: 80px;
  }
  .instagram {
    width: 80px;
    height: 80px;
  }
  .grubhub {
    width: 80px;
    height: 80px;
  }
  .google {
    width: 80px;
    height: 80px;
  }
  .sombrero {
    width: 250px;
    height: 180px;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .locationBox {
    height: 900px;
    width: auto;
    font-family: "Permanent Marker", cursive;
    display: flex;
    margin-top: 110px;
  }
  .socialMediaIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    gap: 17px;
  }
  .locationTxt {
    font-size: 25px;
    margin-left: 110px;
    margin-right: 95px;
  }
}

@media screen and (max-device-width: 430px) {
  .locationBox {
    flex-direction: column;
  }
  .locationBox {
    height: 760px;
    width: auto;
    font-family: "Permanent Marker", cursive;
    display: flex;
    margin-top: 90px;
  }
  #col-1 {
    background-color: #75a149;
    /* flex: 1 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin: 0;
    gap: 16px;
  }
  .locationTxt {
    font-size: 19px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .sombrero {
    width: 240px;
    height: 180px;
  }
  .socialMediaIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: -2px;
    gap: 9px;
  }
  .facebook {
    width: 75px;
    height: 64px;
  }
  .instagram {
    width: 75px;
    height: 70px;
  }
  .grubhub {
    width: 75px;
    height: 70px;
  }
  .google {
    width: 75px;
    height: 70px;
  }
}

/* Smallest Iphone screen */
@media screen and (max-device-width: 400px) {
  .locationBox {
    flex-direction: column;
  }
  #col-1 {
    background-color: #75a149;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin: 0;
    gap: 16px;
  }
  .locationTxt {
    font-size: 19px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .sombrero {
    width: 240px;
    height: 180px;
  }
  .socialMediaIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: -2px;
    gap: 9px;
  }
  .facebook {
    width: 75px;
    height: 64px;
  }
  .instagram {
    width: 75px;
    height: 70px;
  }
  .grubhub {
    width: 75px;
    height: 70px;
  }
  .google {
    width: 75px;
    height: 70px;
  }
}

@media all and (min-width: 280px) and (max-width: 370px) {
  #col-1 {
    background-color: #75a149;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin: 0;
    gap: 7px;
  }
  .sombrero {
    width: 200px;
    height: 180px;
  }
  .locationTxt {
    font-size: 16px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .socialMediaIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: -2px;
    gap: 3px;
  }
  .facebook {
    width: 60px;
    height: 55px;
  }
  .instagram {
    width: 66px;
    height: 60px;
  }
  .grubhub {
    width: 75px;
    height: 53px;
  }
  .google {
    width: 56px;
    height: 60px;
  }
}
