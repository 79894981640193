.mainDiv {
  font-size: 50px;
  font-family: "Permanent Marker", cursive;
}

.orderNowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.phoneDiv {
  font-size: 25px;
  font-family: "Permanent Marker", cursive;
}

.onlineStoresDiv {
  display: flex;
  align-items: center;
  gap: 50px;
}
.ubereats-logo {
  width: 200px;
  height: 200px;
}
.grubhub-logo {
  width: 275px;
  height: 250px;
}
.doordash-logo {
  width: 315px;
  height: 200px;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .mainDiv {
    font-size: 45px;
    font-family: "Permanent Marker", cursive;
  }
  .onlineStoresDiv {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  .doordash-logo {
    width: 283px;
    height: 200px;
  }
  .ubereats-logo {
    width: 180px;
    height: 200px;
  }
  .grubhub-logo {
    width: 200px;
    height: 254px;
  }
}
@media all and (max-width: 768px) {
  .mainDiv {
    font-size: 45px;
    font-family: "Permanent Marker", cursive;
  }
  .onlineStoresDiv {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  .doordash-logo {
    width: 283px;
    height: 200px;
  }
  .ubereats-logo {
    width: 180px;
    height: 200px;
  }
  .grubhub-logo {
    width: 200px;
    height: 254px;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .doordash-logo {
    width: 227px;
    height: 138px;
  }
  .grubhub-logo {
    width: 172px;
    height: 165px;
  }
  .ubereats-logo {
    width: 180px;
    height: 82px;
  }
  .mainDiv {
    font-size: 31px;
    font-family: "Permanent Marker", cursive;
  }
  .phoneDiv {
    font-size: 17px;
    font-family: "Permanent Marker", cursive;
  }
  .onlineStoresDiv {
    flex-direction: column;
  }
}

@media screen and (max-device-width: 430px) {
  .onlineStoresDiv {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .ubereats-logo {
    width: 200px;
    height: 65px;
  }
  .grubhub-logo {
    width: 245px;
    height: 193px;
  }
  .doordash-logo {
    width: 315px;
    height: 175px;
  }
  .mainDiv {
    font-size: 47px;
    font-family: "Permanent Marker", cursive;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .phoneDiv {
    font-size: 26px;
    font-family: "Permanent Marker", cursive;
    padding-left: 7px;
  }
}

/* Smallest Iphone screen */
@media screen and (max-device-width: 400px) {
  .onlineStoresDiv {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .ubereats-logo {
    width: 200px;
    height: 65px;
  }
  .grubhub-logo {
    width: 245px;
    height: 193px;
  }
  .doordash-logo {
    width: 315px;
    height: 175px;
  }
  .mainDiv {
    font-size: 41px;
    font-family: "Permanent Marker", cursive;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .phoneDiv {
    font-size: 23px;
    font-family: "Permanent Marker", cursive;
    padding-left: 12px;
  }
}

@media all and (min-width: 280px) and (max-width: 370px) {
  .mainDiv {
    font-size: 31px;
    padding-bottom: 0px;
  }
  .phoneDiv {
    font-size: 20px;
    font-family: "Permanent Marker", cursive;
    /* padding-left: 20px; */
  }
  .doordash-logo {
    width: 278px;
    height: 153px;
  }
}
