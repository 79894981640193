.Container {
  display: flex;
  margin-top: 50px;
}

.col-1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.col-2 {
  flex: 1;
}

.MenuBlockContainer {
  margin-left: 203px;
}
#secondCol {
  margin-right: 203px;
  margin-left: 100px;
}

.ItemHeadingTitle {
  font-size: 47px;
  font-weight: bold;
  font-family: "Permanent Marker", cursive;
}

.ItemPriceAndBox {
  font-family: "Permanent Marker", cursive;
  display: flex;
  justify-content: space-between;
}
.Item {
  font-size: 28.5px;
  font-weight: bold;
}
.price {
  font-size: 28.5px;
  font-weight: bold;
}

.ItemDescription {
  font-size: 20.8px;
  font-family: "Helvetica Neue Italic";
}

.DottedLine {
  border-top: dotted 5px;
  border-color: #75a149;
  border-bottom: none;
}
.VariationDescription {
  font-style: italic;
}

#hrlineBottomMargin {
  margin-bottom: 35px;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .MenuBlockContainer {
    margin-left: 20px;
  }
  #secondCol {
    margin-left: 50px;
    margin-right: 25px;
  }
}
@media all and (max-width: 768px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .MenuBlockContainer {
    margin-left: 20px;
  }
  #secondCol {
    margin-right: 25px;
    margin-left: 38px;
  }
  .ItemHeadingTitle {
    font-size: 40px;
  }
}

@media screen and (max-device-width: 430px) {
  .Container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  .MenuBlockContainer {
    margin-left: 10px;
    margin-right: 12px;
  }
  #secondCol {
    margin-right: 12px;
    margin-left: 10px;
  }
}

/* Smallest Iphone screen */
@media screen and (max-device-width: 400px) {
  .Container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .MenuBlockContainer {
    margin-left: 10px;
    margin-right: 20px;
  }
  #secondCol {
    margin-right: 12px;
    margin-left: 10px;
  }
}

@media all and (min-width: 280px) and (max-width: 370px) {
  .MenuBlockContainer {
    margin-left: 3px;
    margin-right: 8px;
  }
  #secondCol {
    margin-right: 3px;
    margin-left: 5px;
  }
  .ItemDescription {
    font-size: 16.8px;
  }
}
