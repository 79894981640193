.PageNotFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 64px;
  font-family: "Permanent Marker", cursive;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (max-width: 768px) {
  .PageNotFoundContainer {
    font-size: 62px;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .PageNotFoundContainer {
    font-size: 40px;
  }
}

@media screen and (max-device-width: 430px) {
  .PageNotFoundContainer {
    font-size: 33px;
  }
}

/* Smallest Iphone screen */
@media screen and (max-device-width: 400px) {
  .PageNotFoundContainer {
    font-size: 28px;
  }
}

@media all and (min-width: 280px) and (max-width: 370px) {
  .PageNotFoundContainer {
    font-size: 23px;
  }
}
