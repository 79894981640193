.ourFoodrectangle {
  height: 300px;
  width: auto;
  background-color: white;
  justify-content: center;
  margin-top: 92px;
}

.heading-OurFood {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Permanent Marker", cursive;

  height: 230px;
  gap: 20px;
}
.cow {
  width: 570px;
  height: 330px;
}
.chicken {
  width: 550px;
  height: 350px;
  /* margin-left: 40px ;
    margin-right: 70px ; */
}

.textAndButton {
  flex-direction: column;
  display: flex;
  align-items: center;
  font-size: 55px;
  gap: 15px;
}
.smallerTxt {
  font-size: 25px;
}
.btn {
  background-color: #75a149;
  font-family: "Permanent Marker", cursive;
  color: white;
  border-radius: 2px;
  width: 194px;
  height: 50px;
  font-size: 32px;
  border: 0px;
  text-decoration: none;
  color: white;
}

#btnLink {
  text-decoration: none;
  color: white;
}
#btnLink:hover {
  color: white;
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .btn {
    margin-right: 26px;
  }
  .chicharon {
    margin-top: 255px;
  }
  .ourFoodrectangle {
    height: 300px;
    width: auto;
    background-color: white;
    justify-content: center;
    margin-top: 35px;
  }
  .cow {
    width: 297px;
    height: 192px;
  }
  .chicken {
    width: 200px;
    height: 203px;
  }
  .heading-OurFood {
    gap: 16px;
  }
}

@media all and (max-width: 768px) {
  .chicharon {
    margin-top: 93px;
  }
  .heading-OurFood {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Permanent Marker", cursive;
    height: 230px;
    gap: 3px;
  }
  .ourFoodrectangle {
    height: 300px;
    width: auto;
    background-color: white;
    justify-content: center;
    margin-top: 35px;
  }
  .cow {
    width: 297px;
    height: 192px;
  }
  .chicken {
    width: 200px;
    height: 203px;
  }
  .textAndButton {
    flex-direction: column;
    display: flex;
    align-items: center;
    font-size: 44px;
    gap: 12px;
  }
  .smallerTxt {
    font-size: 23px;
  }
  .heading-OurFood {
    gap: 3px;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .bioText {
    font-size: 15px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .chicharon {
    margin-top: 130px;
  }
  .heading-OurFood {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .ourFoodrectangle {
    height: 300px;
    width: auto;
    background-color: white;
    justify-content: center;
    margin-top: 175px;
  }
}

/* iphone XR */
@media screen and (max-device-width: 430px) {
  .chicharon {
    margin-top: 185px;
  }

  .ourFoodrectangle {
    margin-top: 150px;
  }
  .heading-OurFood {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .cow {
    width: 370px;
    height: 130px;
  }
  .chicken {
    width: 350px;
    height: 150px;
  }
  .textAndButton {
    flex-direction: column;
    display: flex;
    align-items: center;
    font-size: 50px;
    gap: 5px;
  }
}

/* Smallest Iphone screen */
@media screen and (max-device-width: 400px) {
  .chicharon {
    margin-top: 195px;
  }
  .ourFoodrectangle {
    margin-top: 150px;
  }

  .heading-OurFood {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .cow {
    width: 370px;
    height: 130px;
  }
  .chicken {
    width: 350px;
    height: 150px;
  }

  .textAndButton {
    flex-direction: column;
    display: flex;
    align-items: center;
    font-size: 50px;
    gap: 5px;
  }
}

@media all and (min-width: 280px) and (max-width: 370px) {
  .chicharon {
    margin-top: 235px;
  }
}
