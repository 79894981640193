.chicharon {
  width: 100%;
  height: auto;
  margin-top: 210px;
  display: flex;
  justify-content: center;
}
.slideImages {
  margin: 0px;
  margin-top: 20px;
  width: 100%;
  height: auto;
}
@media all and (max-width: 768px) {
  .chicharon {
    margin-top: 93px;
  }
}
