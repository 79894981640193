.rectangle {
  height: 300px;
  width: auto;
  background-color: white;
  justify-content: center;
  margin-bottom: 92px;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Permanent Marker", cursive;
  font-size: 65px;
  gap: 50px;
}

.EloteLogo {
  width: 105px;
  height: 130px;
}
.LemonsLogo {
  width: 135px;
  height: 130px;
  margin-left: 20px;
}
.biotextOutterBox {
  margin-top: 20px;
}
.bioText {
  font-family: "Permanent Marker", cursive;
  font-size: 22px;
  margin-left: 300px;
  margin-right: 300px;
}

/**
 * Basic CSS Media Query Template
 * TODO: I should probably use Sass...
 * Author: Michael Vieth
 * ------------------------------------------
 *  Responsive Grid Media Queries - 1280, 1024, 768, 480
 *   1280-1024   - desktop (default grid)
 *   1024-768    - tablet landscape
 *   768-480     - tablet 
 *   480-less    - phone landscape & smaller
 * --------------------------------------------
 */
@media all and (min-width: 1024px) and (max-width: 1280px) {
  .rectangle {
    height: 300px;
    width: auto;
    background-color: white;
    justify-content: center;
    margin-bottom: 92px;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Permanent Marker", cursive;
    font-size: 65px;
    gap: 50px;
  }

  .EloteLogo {
    width: 105px;
    height: 130px;
  }
  .LemonsLogo {
    width: 135px;
    height: 130px;
    margin-left: 20px;
  }
  .biotextOutterBox {
    margin-top: 20px;
  }
  .bioText {
    font-family: "Permanent Marker", cursive;
    font-size: 20px;
    margin-left: 300px;
    margin-right: 300px;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .bioText {
    font-family: "Permanent Marker", cursive;
    font-size: 20px;
    margin-left: 100px;
    margin-right: 100px;
  }
  .btn {
    margin-right: 25px;
  }
}

@media all and (max-width: 768px) {
  .rectangle {
    height: 300px;
    width: auto;
    background-color: white;
    justify-content: center;
    margin-bottom: 92px;
  }

  .heading {
    font-size: 52px;
    gap: 23px;
  }

  .EloteLogo {
    width: 105px;
    height: 130px;
  }
  .LemonsLogo {
    width: 135px;
    height: 130px;
    margin-left: 20px;
  }
  .biotextOutterBox {
    margin-top: 20px;
  }
  .bioText {
    font-family: "Permanent Marker", cursive;
    font-size: 18px;
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .biotextOutterBox {
    margin-top: 15px;
  }
  .bioText {
    font-size: 15px;
    margin-left: 60px;
    margin-right: 60px;
  }
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Permanent Marker", cursive;
    font-size: 32px;
    gap: 3px;
  }
  .LemonsLogo {
    width: 135px;
    height: 70px;
    margin-left: 15px;
  }
  .EloteLogo {
    width: 105px;
    height: 95px;
  }
}

@media (max-width: 480px) {
  .biotextOutterBox {
    margin-top: 15px;
  }
  .bioText {
    font-size: 15px;
    margin-left: 60px;
    margin-right: 60px;
  }
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Permanent Marker", cursive;
    font-size: 32px;
    gap: 3px;
  }
  .LemonsLogo {
    width: 135px;
    height: 70px;
    margin-left: 15px;
  }
  .EloteLogo {
    width: 105px;
    height: 95px;
  }
}

/* iPhone Portrait */
@media screen and (max-device-width: 479px) {
  .EloteLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Permanent Marker", cursive;
    font-size: 27px;
    gap: 22px;
  }
  .bioText {
    font-size: 15px;
  }
}

@media screen and (max-device-width: 430px) {
  .bioText {
    font-size: 14px;
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media screen and (max-device-width: 400px) {
  .bioText {
    font-size: 13px;
  }
  .LemonsLogo {
    width: 120px;
    height: 66px;
    margin-left: 10px;
  }
}
@media all and (min-width: 280px) and (max-width: 370px) {
  .EloteLogo {
    width: 47px;
    height: 95px;
  }
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Permanent Marker", cursive;
    font-size: 22px;
    gap: 3px;
  }
  .LemonsLogo {
    width: 75px;
    height: 66px;
    margin-left: 6px;
  }
  .bioText {
    font-size: 12px;
  }
}
