.box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Permanent Marker;
}

.box div {
  width: 500px;
  height: 500px;
}
.imgLogo {
  width: 350px;
  height: 300px;
}

ul {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
}

li {
  font-family: "Permanent Marker", cursive;
}
.navbar {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.HamAndNav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HamAndNav .hamburger {
  visibility: hidden;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #75a149;
}

@media (max-width: 600px) {
  .HamAndNav .hamburger {
    display: center;
    visibility: visible;
  }
  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }
}
